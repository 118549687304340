<script>
import EventSlots from './components/EventSlots.vue'
import AttendeesView from './components/AttendeesView.vue'
import DocumentExports from './components/DocumentExports.vue'
import NewsFeed from './components/NewsFeed.vue'
//import BottomSheet from './components/BottomSheet.vue'
import OverlayView from './components/OverlayView.vue'
import TalksForEventSlot from './components/TalksForEventSlot.vue'
import axios from 'axios'
import Vue from 'vue'
import imgUrl from './assets/fav.png'

axios.defaults.withCredentials = true;

export default {
    created() {

        var url = this.$hostname + "/externalApi/currentEvent"

        this.loading = true
        axios.get(url).then((response) => {

            if (!response.data.result) {
                //todo zeige Fehlermeldung -> neu zu erledigen
            }

            axios.defaults.headers.common['x-tenant'] = response.data.id;

            document.title = "LIVE: " + response.data.name.replace(" Excellence Forum", "");
            this.loading = false
            this.event = response.data.name
            this.loggedIn = response.data.loggedIn
            this.duration = response.data.duration
            this.startDate = response.data.startDate
            this.endDate = response.data.endDate
            Vue.prototype.$eventColor = response.data.color

            // set up a little favicon-sized canvas and fill it with the chosen color
            const canvas = document.createElement("canvas");
            canvas.width = canvas.height = 16;
            const context = canvas.getContext("2d");

            const img = new Image();
            img.src = imgUrl;

            img.onload = function() {
                context.drawImage(img, 0, 0, canvas.width, canvas.height);

                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                const targetColor = response.data.color;
                const redTarget = parseInt(targetColor.slice(1, 3), 16);
                const greenTarget = parseInt(targetColor.slice(3, 5), 16);
                const blueTarget = parseInt(targetColor.slice(5, 7), 16);

                for (let i = 0; i < data.length; i += 4) {
                    data[i] =     redTarget
                    data[i + 1] = greenTarget
                    data[i + 2] = blueTarget
                }

                context.putImageData(imageData, 0, 0);

                const imageUrl = canvas.toDataURL();

                // remove existing favicon links
                document.querySelectorAll("[rel~='icon']").forEach(el=>el.remove())

                // create a new one
                const link = document.createElement("link");
                link.setAttribute("rel", "icon");
                document.querySelector("head").appendChild(link);

                link.type = "image/x-icon";
                // point the favicon link at the generated URL
                link.href=imageUrl;
            }

            if (!this.loggedIn) {
                window.location = this.$hostname + "/login"
            }
        })
    },
    name: 'App',
    components: {
        EventSlots,
        AttendeesView,
        DocumentExports,
        NewsFeed,
        //BottomSheet,
        OverlayView,
        TalksForEventSlot
    },
    mounted() {
        if (this.$route.query.state) {
            this.listItemGroupModel = this.$route.query.state;
        }
    },
    data: () => ({
        navigationDrawerModel: false,
        newsFeedModel: false,
        listItemGroupModel: "eventSlots",
        event: '',
        loggedIn: false,
        loading: false,
        show: false,
        overlay: false,
        content: "",
        startDate: "", 
        endDate: "",
        workshopView: false,
    }),
    watch: {
        listItemGroupModel(newVal, oldVal) {
            if (oldVal) {
                this.navigationDrawerModel = false
                this.$router.push({ path: '/' + newVal, query: { 
                        state: newVal,
                        attendeeId: this.$route.query.attendeeId,
                        eventSlotId: this.$route.query.eventSlotId,
                    }
                });
            }
        }
    },
    methods: {
        showTextArea() {
            this.show = !this.show
        }
    }
}
</script>

<template>
    <v-app>
        <v-main>
            <template v-if="loading">
    
            </template>
            <template v-else>
    
                <v-app-bar :color="$eventColor" dark fixed>           

                    <v-app-bar-nav-icon @click.stop="navigationDrawerModel = !navigationDrawerModel"></v-app-bar-nav-icon>
    
                    <div v-if="listItemGroupModel == 'eventSlots'">
                        Ablaufplan {{ content }}
                    </div>
                    <div v-if="listItemGroupModel == 'attendeesView'">
                        Teilnehmendenübersicht
                    </div>
                    <div v-if="listItemGroupModel == 'documentExports'">
                        Downloads
                    </div>

                    <v-spacer></v-spacer>

                    <div style="float: right">
                        <a style="color: white; text-decoration: none" href="https://portal.smart-bridges.com/">
                            {{ event }}
                        </a>
                    </div>
        
                </v-app-bar>
            </template>
    
            <v-navigation-drawer v-model="navigationDrawerModel" fixed left temporary>
    
                <v-list nav dense>
                    <v-list-item-group mandatory v-model="listItemGroupModel" active-class="deep-purple--text text--accent-4">
                        <v-list-item value="eventSlots">
                            <v-list-item-title>Ablaufplan</v-list-item-title>
                        </v-list-item>
    
                        <v-list-item value="attendeesView">
                            <v-list-item-title>Teilnehmendenübersicht</v-list-item-title>
                        </v-list-item>
    
                        <v-list-item value="documentExports">
                            <v-list-item-title>Downloads</v-list-item-title>
                        </v-list-item>
    
                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
    
            <div style="height: 64px"></div>
            <div :style="{ display: listItemGroupModel == 'eventSlots' ? 'inline': 'none' }" >
                <EventSlots />
            </div>
            <div :style="{ display: listItemGroupModel == 'attendeesView' ? 'inline': 'none' }" >
                <AttendeesView />
            </div>
            <div :style="{ display: listItemGroupModel == 'documentExports' ? 'inline': 'none' }" >
                <DocumentExports style="text-align: left" />
            </div>
            <NewsFeed v-if="listItemGroupModel == 'newsFeed'" />
            <TalksForEventSlot v-if="listItemGroupModel == 'talksForEventSlot'" /> 
            
            <OverlayView v-if="overlay" />
    
        </v-main>
    </v-app>
</template>
  
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 0px;
}

/* nur die schwarzen Selektoren / Wie kann man die Regeln zusammenlegen?  */

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding: 0 40px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child {
    padding: 0 40px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child {
    padding: 0 40px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-data-table>.v-data-table__wrapper>table>thead>tr>th:last-child {
    padding: 0 40px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-main .v-content {
    text-align: left;
}

tbody tr:nth-of-type(odd) {
    background-color: #E3F2FD;
}
</style>