<script>

// import OverlayView from './components/OverlayView.vue'
import axios from 'axios'

export default {
    created () {

        var url = this.$hostname + "/externalApi/newsfeed"

        this.loading = true
        axios.post(url).then((response) => {
            this.loading = false
            this.news = response.data
        })
    },
    data () {
        return {
            search: '',
            headers: [
                { 
                    text: 'Akteur',
                    align: 'start',
                    filterable: true,
                    value: 'stake',

                },
                { text: 'Nachricht', value: 'message' },
                { text: 'Zeitpunkt', value: 'timecode' },
                { text: 'Gelesen', value: 'checked' },

            ],
            loading: false,
            news: [
                
            ]
        }
    },
    components: {
        // OverlayView
    }
}
</script>

<template>
    
    <v-card>
        <div v-if="loading">

            <div style="height: 300px;"></div>

            <!-- <div v-if="loading">Daten werden geladen</div> -->

            <div>
                <v-progress-linear
                    indeterminate
                    color="blue darken-4"
                ></v-progress-linear>
            </div>
        </div>
        <div v-else>
        
            <v-card-title
            >
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                >
            
                </v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="news"
                :search="search"
                hide-default-footer
            >

                <template v-slot:[`item.checked`]="{ item }">
                    <v-simple-checkbox
                        v-model="item.checkIn"
                    ></v-simple-checkbox>
                </template>
            </v-data-table>
        </div>
    </v-card>
</template>
