<script>
    import CheckedInBadge from './CheckedInBadge.vue'
    import axios from 'axios'


    export default {
        created () {

            var url = this.$hostname + "/externalApi/attendees"

            this.loading = true
            axios.post(url).then((response) => {
                this.loading = false
                this.attendees = response.data

                for (var i=0; i<this.attendees.length; i++) {
                    this.salesAgents.push(this.attendees[i].salesAgent)
                }

                this.salesAgents = ["", ...new Set(this.salesAgents)]

                if (this.$route.query.attendeeId) {
                    this.currentParticipant = this.attendees.find((x) => x.id == this.$route.query.attendeeId)
                }

            })

        },
        data () {
            return {
                host: "https://portal.smart-bridges.com/",
                search: '',
                singleSelect: null,
                currentParticipant: null,
                headers: [
                    { 
                        text: 'Unternehmen',
                        align: 'start',
                        filterable: false,
                        value: 'companyName',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Typ', value: 'type' },
                    { text: 'Betreuer vor Ort', value: 'salesAgent' },
                    { text: 'Ankunft', value: 'arrivalDate' },                   
                    { text: 'Check-In erfolgt', value: 'checkedIn' },
                    { text: 'Abfahrt', value: 'departureDate' },
                    { text: 'Notizen', value: 'note'}
                ],
                loading: false,
                attendees: [

                ], 
                salesAgents: [

                ],
                errorMessage: "",
                filterType: "",
                filterAgent: "",
                tab: "one"
            }
        }, 
        methods: {
            selectParticipant(item) {
                this.currentParticipant = item
                this.$router.push({ path: '/attendeesView/' + item?.id, query: { 
                    state: "attendeesView",
                    attendeeId: item?.id
                } });
            },
            userNoteChanged(val) {
                
                var url = this.$hostname + "/externalApi/updateNoteForUser"
                axios.post(url, {
                    id: val.id,
                    note: val.note
                }).then((response) => {
                    if (!response.data.result) {
                        this.errorMessage = response.data.reason
                    }
                })
            },
            updateCheckedInForUser(val) {
                var url = this.$hostname + "/externalApi/updateCheckedInForUser"

                axios.post(url, {
                    id: val.id, 
                    checkedIn: val.checkedIn
                }).then((response) => {
                    if (!response.data.result) {
                        this.errorMessage = response.data.reason
                    }
                })
            }, 
            filteredList(list) {
                var t = this
                return list.filter(function(item) {
                    if (t.filterType) {
                        if (item.type != "Delegierter" && t.filterType == "Delegierte") {
                            return false
                        }
                        if (item.type != "Unternehmensvertreter" && t.filterType == "Unternehmensvertreter") {
                            return false
                        }
                        if (item.type != "Referent" && t.filterType == "Referenten") {
                            return false
                        }
                    }
                    if (t.filterAgent) {
                        if (item.salesAgent != t.filterAgent) {
                            return false
                        }
                    }
                    return true
                }).sort(function(a, b) {
                    if (a.lastName < b.lastName) {
                        return -1
                    }
                    if (a.lastName > b.lastName) {
                        return 1
                    }
                    return 0
                })
            },  
            updateCheckedOutForUser(val) {
                var url = this.$hostname + "/externalApi/updateCheckedOutForUser"

                axios.post(url, {
                    id: val.id, 
                    checkedOut: val.checkedOut
                }).then((response) => {
                    if (!response.data.result) {
                        this.errorMessage = response.data.reason
                    }
                })
            }, 

            
        },
        components: {
            CheckedInBadge
        }
    }
</script>

<template>
    <div>
        <div v-if="loading">
            <div style="height: 300px;"></div>
            <div v-if="errorMessage">
                {{ errorMessage }}
                <v-btn @click="errorMessage = null"></v-btn>
            </div>

            <!-- <div v-if="loading">Daten werden geladen</div> -->

            <div>
                <v-progress-linear
                    indeterminate
                    color="blue darken-4"
                 ></v-progress-linear>
            </div>

        </div>
        <div v-else>
            <div v-if="currentParticipant">
                <v-overlay fluid fill-height>
                    <v-card style="margin: 3em 3em" absolute fluid fill-height>
                        <v-list-item three-line fluid fill-height>
                            <v-list-item-content>
                                <div class="text-overline mb-4">
                                    {{ currentParticipant.name }}
                                </div>
                                <v-list-item-title class="text-h5 mb-1">
                                    {{ currentParticipant.companyName }}<br/>
                                </v-list-item-title>
                                <v-list-item-subtitle> 
                                    {{ currentParticipant.role }}<br/>
                                    {{ currentParticipant.mobile }}<br/>
                                </v-list-item-subtitle>
                                <v-list-item-content style="color: #ccc; max-height: 200px; overflow-y: scroll;" v-if="currentParticipant.companyProfile?.length > 10">
                                    <v-tabs
                                    v-model="tab"
                                    bg-color="primary"
                                    >
                                        <v-tab value="one" v-if="currentParticipant.companyProfile?.length > 10">Über {{ currentParticipant.companyName }}</v-tab>
                                        <v-tab value="two" v-if="currentParticipant.personalProfile?.length > 10">Persönliches Profil</v-tab>
                                    </v-tabs>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div v-html="currentParticipant.companyProfile" v-if="tab == 0">
                                    </div>
                                    <div v-html="currentParticipant.personalProfile" v-if="tab == 1">
                                    </div>
                                </v-list-item-content>
                            </v-list-item-content>
                            <v-avatar
                                class="ma-3"
                                size="150"
                                tile
                            >
                                <v-img :src="host + currentParticipant.profileImageHigh"></v-img>
                            </v-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn v-if="!currentParticipant.checkedIn"
                                variant="text"
                                color="deep-purple-accent-4"
                                @click="updateCheckedInForUser(currentParticipant)"
                            >
                                Check-In
                            </v-btn>
                            <v-btn v-if="currentParticipant.checkedIn && !currentParticipant.checkedOut"
                                variant="text"
                                color="deep-purple-accent-4"
                                @click="updateCheckedOutForUser(currentParticipant)"
                            >
                                Check-Out
                            </v-btn>
                            <v-btn
                                variant="text"
                                color="deep-purple-accent-4"
                                @click="selectParticipant(null)"
                            >
                                Schließen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-overlay>
            </div>

            <v-row>
                <v-col cols="8">
                    <v-text-field
                    v-model="search" class="ma-4"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="filterType"  class="ma-4"
                        :items="['', 'Referenten', 'Delegierte', 'Unternehmensvertreter']" 
                        dense 
                        solo single-line hide-details
                        label="Teilnehmerart"
                    ></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="filterAgent" class="ma-4"
                        :items="salesAgents" dense
                        solo single-line hide-details
                        label="Betreuer"
                    ></v-select>
                </v-col>
            </v-row>

            <v-data-table
                height="100%"
                fixed-header
                :headers="headers"
                :items="filteredList(attendees)"
                :search="search"
                disable-pagination
                hide-default-footer
                class="table-striped" 
            >   
                <template v-slot:[`item.companyName`]="{ item }">
                    <nobr @click="selectParticipant(item)">
                        {{ item.companyName }}
                    </nobr>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <nobr @click="selectParticipant(item)">
                        <CheckedInBadge :attendant="item"/>
                    </nobr>
                </template>
                <template v-slot:[`item.checkedIn`]="{ item }">
                        <v-simple-checkbox
                            v-model="item.checkedIn"
                            @click="updateCheckedInForUser(item)"
                            style="text-align:center !important"
                        ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.checkedOut`]="{ item }">
                        <v-simple-checkbox
                            v-model="item.checkedOut"
                            @click="updateCheckedOutForUser(item)"
                            style="text-align:center !important"
                        ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                        {{ item.type == "Delegierter" ? "DEL" : ( item.type == "Unternehmensvertreter" ? "LP" : "REF" )}}
                </template>
                <template v-slot:[`item.note`]="{ item }">
                    <v-textarea
                        auto-grow
                        rows="1"
                        clearable
                        v-model="item.note"
                        @change="userNoteChanged(item)"
                        style="text-align:center"
                    >
                        {{ item.note }}
                    </v-textarea>
                </template>

            </v-data-table>  
        </div>
    </div>
</template>
