<script>
export default {
    props: [
        "attendant"
    ]
}
</script>

<template>
    <div>
        <v-badge
            inline
            dot
            :color="attendant.checkedIn == null ? 'red' : 'green'"
        >
            {{ attendant.title }} {{ attendant.lastName }}, {{ attendant.firstName }}
        </v-badge>
    </div>
</template>