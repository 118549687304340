<script>

import axios from 'axios'


export default {
    created () {

    
        var url = this.$hostname + "/externalApi/eventSlots"
        var url2 = this.$hostname + "/externalApi/currentEvent"
        
        this.loading = true
        axios.post(url).then((response) => {
            this.loading = false
            this.items = response.data
        }, 
        axios.post(url2).then((response) => {
            this.loading = false
            this.items = response.data
            this.endDate = response.data.endDate
            this.id = response.data.id
        }))

    },
    data () {
        return {
            loading: false,
            items: [
                
            ], 
            host: this.$hostname,
        }
    },
    computed: {
        groupedItems() {
            return this.items && this.items.length > 0 ? this.items.reduce((acc, item) => {
            if (item.eventSlotType === 'TALKS') {
                if (!acc[item.date]) {
                acc[item.date] = [];
                }
                acc[item.date].push(item);
            }
            return acc;
            }, {}) : {};
        },
    },
}
</script>

<template>
    <div v-if="loading">

        <div style="height: 300px;"></div>

        <!-- <div v-if="loading">Daten werden geladen</div> -->

        <div>
            <v-progress-linear
                indeterminate
                color="blue darken-4"
            ></v-progress-linear>
        </div>
    </div>  
    <div v-else>   
        <div style="padding:2em;" v-if="items">
            <h2>
                Ausweichlisten:
            </h2>
            <div v-for="(group, date, i) in groupedItems" :key="date">
                <br/>
                <h3>Tag {{ i+1 }}</h3>
                <ul>
                    <li v-for="item in group" :key="item.id">
                        <a :href="host + '/documentExport/escapeListHTML?slotId=' + item.id + '&type=pdf'" style="text-decoration: none;">
                            {{ item.fromDate}} Uhr Tag {{ i+1 }} - <span v-html="item.text"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <br/>
            <h2>
                Ersatzhaltung 
            </h2>
            <ul>
                <li>
                    <a :href="host + '/documentExport/compensation/?type=pdf'">
                        Ersatzhaltung
                    </a>
                </li>
            </ul>        
            <br/>
            <h2>
                Raumplanung:
            </h2>
            <ul>
                <li>
                    <a :href="host + '/documentExport/downloadHotelRoomPlan'">
                        Allgemeiner Hotelplan
                    </a>
                </li>
                <li>
                    <a :href="host + '/documentExport/downloadTalkPlan'">       
                        Raumplan Lösungspartner
                    </a>
                </li>
            </ul>
        </div>    
    </div>
</template>
