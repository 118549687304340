<script>
  export default {
    data: () => ({
      overlay: true,
      zIndex: 1,
    }),
  }
</script>

<template>
    <!--
        <v-row justify="center">
            <v-overlay
                :z-index="zIndex"
                :value="overlay"
            >
                <v-container fluid>
                    <v-textarea
                        dark
                        auto-grow
                        clearable
                        clear-icon="mdi-close-circle"
                        label="Message"          
                    ></v-textarea>
                </v-container>

                <v-btn
                    class="white--text"
                    color="teal"
                    @click="overlay = false"
                >
                    Send Message
                </v-btn>
            </v-overlay>
        </v-row> -->
        
            
    <v-overlay
        :value="overlay"
        opacity="0.4"
    >
        <v-card
            light
            width="700px"
        >
            <v-textarea
                solo
                label="New message:"
                hide-details="true"
                auto-grow
                clearable
            ></v-textarea>
            
            <v-btn
                class="ma-3"
                color="teal darken-2"
                dark
                @click="overlay= false"
                elevation="3"
            >
                SEND
            </v-btn>
                        
            <v-btn
                class="ml-3"
                color="pink darken-3"
                dark
                @click="overlay = false"
                elevation="3"
            >
                CLOSE
            </v-btn>    
        </v-card>
    </v-overlay>
</template>