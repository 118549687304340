<script>
import axios from 'axios';
//import { response } from 'express';
import CheckedInBadge from './CheckedInBadge.vue';

export default {
  components: { CheckedInBadge },
    props: [
        "eventSlotDetail"
    ],

    created() {
        var url = this.$hostname + "/externalApi/talksForEventSlot"

        this.loading = true;
        axios.post(url, {
            id: this.eventSlotDetail.id,
            time: this.eventSlotDetail.fromDate
        }).then((response) => {
            this.loading = false;
            this.talksForEventSlot = response.data;
            for (var i=0; i<this.talksForEventSlot.length; i++) {
              if (this.salesAgents.indexOf(this.talksForEventSlot[i].salesAgent) == -1) {
                this.salesAgents.push(this.talksForEventSlot[i].salesAgent)
              }
              if (this.rooms.indexOf(this.talksForEventSlot[i].room) == -1) {
                this.rooms.push(this.talksForEventSlot[i].room)
              }
            }
        })
    },

    data() {
        return {
            host: "https://portal.smart-bridges.com/",
            loading: false,
            rooms: [],
            talksForEventSlot: [],
            participants: [],
            agents: [],
            toggle_exclusive: undefined,
            salesAgents: [],
            filteredAgent: null,
            filteredStatus: null,
            filteredRoom: null,
            errorMessage: null,
            search: null
        }
    },
    methods: {
    statuses() {

      var list = this.talksForEventSlot
      
      if (this.filteredRoom) {
        list = list.filter((x) => {
          return x.room == this.filteredRoom;
        })
      }

      if (this.filteredAgent) {
        list = list.filter((x) => {
          return x.salesAgent == this.filteredAgent;
        })
      }

      return [
        { 
          id: '', 
          name: 'Alle'
        },
        { 
          id: 'ABSENT_SLOT_PARTICIPANT', 
          name: 'Rot: ' + list.filter((a) => { return a.status == 'ABSENT_SLOT_PARTICIPANT' }).length + " Gespräche"
        }, 
        { 
          id: 'OKAY', 
          name: 'Grün: '  + list.filter((a) => { return a.status == 'OKAY' }).length + " Gespräche"
        }
      ]
    },
    updateStatusForTalk(val) {
      var url = this.$hostname + "/externalApi/updateStatusForTalk"

      axios.post(url, {
        id: val.talkId, 
        status: val.status
      }).then((response) => {
        if (!response.data.result) {
          this.errorMessage = response.data.reason
        }
      })
    },
    updateNoteForTalk(val) {
      var url = this.$hostname + "/externalApi/updateNoteForTalk"

      axios.post(url, {
        id: val.talkId, 
        note: val.note
      }).then((response) => {
        if (!response.data.result) {
          this.errorMessage = response.data.reason
        }
      })
    }, 
    filteredTalks() {
      var result = this.talksForEventSlot
      if (this.filteredAgent) {
        result = result.filter((x) => {
          return x.salesAgent == this.filteredAgent;
        })
      }
      if (this.filteredRoom) {
        result = result.filter((x) => {
          return x.room == this.filteredRoom;
        })
      }
      if (this.filteredStatus) {
        result = result.filter((x) => {
          return x.status == this.filteredStatus;
        })
      }
      if (this.search) {
        var searchUp = this.search.toUpperCase()
        result = result.filter((x) => {
          if (x.partnerCompanyName.toUpperCase().indexOf(searchUp) > -1) {
            return true
          }
          if (x.agents.find((x) => { return x.name.toUpperCase().indexOf(searchUp) > -1 })) {
            return true
          }
          if (x.participants.find((x) => { return x.name.toUpperCase().indexOf(searchUp) > -1 })) {
            return true
          }
          return false
        })
      }
      return result
    }
  },
}
</script>

<template>
  <div v-if="loading">

    <div style="height: 300px;"></div>
    <div>
        <v-progress-linear indeterminate color="blue darken-4"></v-progress-linear>
    </div>
    <div v-if="errorMessage">
        {{ errorMessage }}
      <v-btn @click="errorMessage = null"></v-btn>
    </div>
  </div>

  <div v-else>
    
    <v-row class="mt-2">
      <v-col cols="3">
        <v-text-field class="ma-4"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
            ></v-text-field>
      </v-col>  
      <v-col cols="3">
        <v-select class="ma-4"
            :items="salesAgents" 
            dense 
            solo single-line hide-details
            v-model="filteredAgent"
            label="Betreuer vor Ort"
        ></v-select>
      </v-col>  
      <v-col cols="3">
        <v-select class="ma-4"
            :items="statuses()" 
            item-value="id"
            item-text="name"
            dense 
            solo single-line hide-details
            v-model="filteredStatus"
            label="Gesprächsstatus"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select class="ma-4"
            :items="rooms" 
            item-value="id"
            item-text="name"
            dense 
            solo single-line hide-details
            v-model="filteredRoom"
            label="Raum"
        ></v-select>
      </v-col>
    </v-row>

    <v-row
      style="padding:0 1em; margin-top: 0px"
    >
      <v-col
        v-for="item in filteredTalks()" 
        :key="item.talkId" 
        cols="12" sm="6" md="6" lg="6" 
      >
        <v-card style="text-align:left;">
          <table style="padding: 0em 1em 1em 1em; width:100%;">
            <tr>
                <th style="width:35%; line-height:2.5em;">
                  Ort
                </th>
                <td style="width:65%">
                  <div>
                    <span v-if="item.room">
                      {{ item.room }}
                      <span v-if="item.location">
                        :
                      </span>
                    </span> 
                    <span v-if="item.location">
                      {{ item.location }}
                    </span> 
                  </div>
                </td>
            </tr>
            <tr v-if="item.partnerCompanyName">
                <th style="line-height:2.5em;">
                  Lösungspartner
                </th>
                <td>
                  <div>
                    {{ item.partnerCompanyName }}
                  </div>
                </td>
            </tr>
            <tr v-if="item.agents.length > 0">
                <th style="line-height:2.5em;">
                  Unternehmensvertreter
                </th>
                <td>
                  <div style="background-color:#e1e1e1;
                    margin-top:10px; 
                    padding:10px;" 
                    v-for="agent in item.agents" 
                    :key="agent.id"
                  >
                    <div>
                      <v-img style="float:left;
                        padding-right:10px;
                        padding-top:12.5px;
                        width: 65px;"
                        :src="host + agent.profileImage"
                      > 
                      </v-img>
                    </div>
                    <div style="padding-left:80px;">
                      <div style="min-height:1.5em;"> 
                        <CheckedInBadge :attendant="agent" />
                      </div> 
                      <div style="min-height:1.5em;">
                        {{ agent.companyName }}
                      </div>
                      <div style="min-height:1.5em;">
                        {{ agent.role}}
                      </div>
                    </div>  
                  </div>

                </td>
            </tr>
            <tr v-if="item.participants.length > 0">
                <th style="line-height:2.5em;">
                  Delegierte
                </th>
                <td>
                  <div style="background-color:#ccc;
                    margin-top:10px; 
                    padding:10px;" 
                    v-for="participant in item.participants" 
                    :key="participant.id"
                  >
                    <div>
                      <v-img style="float:left;
                        padding-right:10px;
                        padding-top:12.5px;
                        width: 65px;"
                        :src="host + participant.profileImage"
                      >
                      </v-img>  
                    </div>
                    <div style="padding-left:80px;">
                      <div style="min-height:1.5em;">
                        <CheckedInBadge :attendant="participant" /> - {{ participant.mobile }}
                      </div>
                      <div style="min-height:1.5em;">
                        {{ participant.companyName }}
                      </div>
                      <div style="min-height:1.5em;">
                        {{ participant.role }}
                      </div>
                    </div>
                  </div>
                </td>
            </tr>
            <tr>
              <th style="line-height: 2.5em;">
                Gesprächsstatus
              </th>
              <td>
                <div style="padding-top:10px;">
                  <v-btn-toggle
                    mandatory
                    v-model="item.status"
                    @change="updateStatusForTalk(item)"
                  >
                    <v-btn value="ABSENT_SLOT_PARTICIPANT">
                      <v-icon color="red">
                        mdi-circle-slice-8 
                      </v-icon>
                    </v-btn>
                    <v-btn value="OKAY">
                      <v-icon color="green">
                        mdi-circle-slice-8
                      </v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </td>
            </tr>
            <tr v-if="item.comesFrom">
                <th style="line-height:2.5em;">
                    Kommt von
                </th>
                <td>
                  <div>
                    {{ item.comesFrom }}
                  </div>  
                </td>
            </tr>
            <tr v-if="item.salesAgent">
                <th style="line-height:2.5em;">
                    Betreuer vor Ort
                </th>
                <td>
                  <div>
                    {{ item.salesAgent }}
                  </div>  
                </td>
            </tr>
            <tr>
                <th style="line-height:2.5em;">
                  Notizen
                </th>
                <td>
                  <v-textarea
                    hide-details="auto"
                    auto-grow
                    clearable
                    outlined
                    full-width
                    rows="1"
                    v-model="item.note"
                    @change="updateNoteForTalk(item)"
                    style="text-align:left"
                  >
                    <div>
                      {{ item.note }}
                    </div>
                  </v-textarea>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>