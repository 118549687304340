<script>
import axios from "axios";
import CheckedInBadge from './CheckedInBadge.vue';

var host= "https://portal.smart-bridges.com"

export default {
    props: [
        "eventSlotDetail"
    ],
    created() {
        var url = this.$hostname + "/externalApi/workshopSlotParticipants";
        var url2 = this.$hostname + "/externalApi/workshopContentAndSpeaker"
     
        console.log(this.eventSlotDetail.id)

        this.loading = 2;
        axios.post(url, {
            id: this.eventSlotDetail.id
        }).then((response) => {
            this.loading--;
            if (response.data.length > 0) {
                this.workshopSlotParticipants = response.data;
            }
        });
       axios.post(url2, {
            id: this.eventSlotDetail.id
        }).then((response) => {
            this.loading--;
            this.eventSlotName = response.data.eventSlotName;
            this.slotProfile = response.data.slotProfile;
            this.workshopContentAndSpeaker = response.data;
        });
    },
    data() {
        return {
            search: "",
            headers: [
                {
                    text: "Unternehmen",
                    align: "start",
                    filterable: true,
                    value: "attendee.companyName",         
                },
                { text: "Name", value: "attendee.name" },  
                { text: "Position", value: "attendee.role" },
                { text: "Check-In erfolgt", value: "checkedInForEventSlot" },
                { text: "Priorität", value: "level" },             
            ],
            loading: 1,
            attendance: 1,
            workshopSlotParticipants: [
            ],
            attendees: [  
            ]
        };
    },
    methods: {
        updateCheckedInForEventSlot(val) {
            var url3 = host + "/externalApi/updateCheckedInForEventSlot"

            axios.post(url3, {
                id: val.attendee.id, 
                checkedInForEventSlot: val.checkedInForEventSlot
            }).then((response) => {
                if (!response.data.result) {
                    this.errorMessage = response.data.reason
                }
            })
        }, 
    },
    components: { 
        CheckedInBadge
    }
}
</script>

<template>
    <div>
        <div v-if="loading > 0">
            <div style="height: 300px"></div>    
            <div>
                <v-progress-linear indeterminate color="blue darken-4"></v-progress-linear>
            </div>
        </div>
        <div v-else class="ma-4">

            <div style="font-weight: bold;">
                Vortrag: {{ eventSlotName }}
            </div>
    
            <div style="font-size: 12px" class="pa-2">
                <div style="text-align: left; font-size: 1.3em; font-weight: bold;">
                    Referent/in: <br />
                </div>
                <ul style="text-align:left; 
                    font-size:1.15em;"
                    v-for="referent in workshopContentAndSpeaker.referents" 
                    :key="referent.id"
                >   
                    <li>
                        {{ referent.name }} / {{ referent.role }} / {{ referent.companyName }}
                    </li>
                </ul>
                <div style="text-align: left; font-size: 1.15em; font-weight: bold;">Inhalt: </div>
                <div style="text-align: left; font-size: 0,875rem" v-html="slotProfile"></div>
            </div>
    
            Teilnehmer: {{ workshopSlotParticipants.length }}

            <v-text-field v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details>
            </v-text-field>
    
            <v-data-table 
                :headers="headers" 
                :items="workshopSlotParticipants" 
                :search="search" 
                hide-default-footer
                disable-pagination
 >

            <template v-slot:[`item.attendee.name`]="{ item }">
                <CheckedInBadge :attendant="item.attendee" />  
            </template> 

            <template v-slot:[`item.checkedInForEventSlot`]="{ item }">
                <v-simple-checkbox
                    v-model="item.checkedInForEventSlot"
                    @click="updateCheckedInForEventSlot(item)"
                    style="text-align:left !important"  
                >
                </v-simple-checkbox> 
            </template>

            </v-data-table>  
        </div>
    </div>
</template>
