<script>
import axios from 'axios'
import TalksForEventSlot from './TalksForEventSlot.vue'
import WorkShopView from './WorkShopView.vue'

export default {
  components: { WorkShopView, TalksForEventSlot },

    created() {

        var url = this.$hostname + "/externalApi/eventSlots"

        this.loading = true
        axios.post(url).then((response) => {
            this.loading = false
            this.eventSlots = response.data

            if (this.$route.query.eventSlotId) {
                this.eventSlotDetail = this.eventSlots.find((x) => x.id == this.$route.query.eventSlotId)
            }

            // Group eventSlots by day
            this.eventSlots.forEach(eventSlot => {
                const day = eventSlot.date;
                if (!this.groupedEventSlots.get(day)) {
                    this.groupedEventSlots.set(day, []);
                }
                this.groupedEventSlots.get(day).push(eventSlot);
            });
        })

    },
    mounted() {
    },
    data() {
        return {
            loading: false,
            eventSlots: [

            ],
            currentDate: new Date().toISOString().slice(0, 16),
            groupedEventSlots: new Map(),
            activeDay: null,
            eventSlotDetail: null, 
            errorMessage: "", 
            sheet: false,
            taskEditor: false
        }
    },
    methods: {
        openTaskEditor(eventSlot) {
            this.taskEditor = eventSlot
            this.$nextTick(() => {
                this.$refs.taskTextarea.focus()
            })
        },
        slotTaskChanged(val) {

            var url = this.$hostname + "/externalApi/updateTaskForEventSlot"

            axios.post(url, {
                id: val.id, 
                task: val.task
            }).then((response) => {
                if (!response.data.result) {
                    this.errorMessage = response.data.reason
                }
            })
        },
        selectSlot(slot) {
            this.$router.push({ path: '/eventSlots/' + slot.id, query: { 
                state: "eventSlots",
                eventSlotId: slot.id
            } });
            this.eventSlotDetail = slot
        },
        checkDate(dateString) {
            const [day, month, year] = dateString.split(".");
            const jsDate = new Date(year, month - 1, day);
            jsDate.setHours(0, 0, 0, 0);
            var currentDateDate = new Date(this.currentDate)
            currentDateDate.setHours(0, 0, 0, 0);

            var result = jsDate.getTime() >= currentDateDate.getTime();
            if (!result) {
                if (this.activeDay == dateString) {
                    this.activeDay = null
                }
            } else {
                if (!this.activeDay) {
                    this.activeDay = dateString
                }
            }
            return jsDate.getTime() >= currentDateDate.getTime();
        },
        filteredEventSlots(groupedEventSlots) {
            if (!this.activeDay) {
                return []
            }
            return groupedEventSlots.get(this.activeDay).filter((a) => new Date(a.dateValue) > new Date(this.currentDate))
        }
     }
}
</script>
  
<template>
    <div>

        <div v-if="errorMessage"
            class="text-center"
        >
    
            <v-bottom-sheet
                v-model="sheet"
                inset
            >
            
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
                >
                Fehlermeldung! Für ausführliche Informationen bitte anklicken!
                </v-btn> 
            </template> 
            <v-sheet
                class="text-center"
                height="200px"
            >
                <v-btn
                class="mt-6"
                text
                color="error"
                @click="errorMessage = null"
                >
                close
                </v-btn>
                <div class="my-3">
                {{ errorMessage }}
                </div>
            </v-sheet>
        </v-bottom-sheet>

        </div>
        <div v-if="loading">
            <div>
                <v-progress-linear indeterminate color="blue darken-4"></v-progress-linear>
            </div>
        </div>
        <div v-else>
            <div v-if="eventSlotDetail">
                <v-app-bar :color="$eventColor" fixed class="mt-14" dark>
                    <v-btn plain
                        @click="eventSlotDetail = null"
                    >
                        <v-icon>
                            mdi-arrow-left
                        </v-icon>
                        Zurück
                    </v-btn>
                    <!--{{ eventSlotDetail.text }} -->
                    <span v-html="eventSlotDetail.fromDate + ': ' + eventSlotDetail.text"></span>
                </v-app-bar>

                <div style="height: 48px"></div>

                <WorkShopView v-if="'WORKSHOP' == eventSlotDetail.eventSlotType" :eventSlotDetail="eventSlotDetail"></WorkShopView>
                <TalksForEventSlot v-else-if="'TALKS' == eventSlotDetail.eventSlotType" :eventSlotDetail="eventSlotDetail"></TalksForEventSlot>
                <WorkShopView v-else :eventSlotDetail="eventSlotDetail"></WorkShopView>
            
 
            </div>

            <div v-if="!eventSlotDetail && !loading">

                <div style="margin: 0 1em; padding: 0 1em; border: 1px solid #ccc; float: right">
                    Für Testzwecke, die aktuelle Uhrzeit: <input v-model="currentDate" type="datetime-local" />
                </div>

                <v-tabs v-model="activeDay" bg-color="primary" class="ma-5">
                    <template v-for="day in groupedEventSlots.keys()">
                        <v-tab v-bind:key="day" v-if="checkDate(day)">{{ day }}</v-tab>
                    </template>
                </v-tabs>

                <v-simple-table height="100%" v-if="!loading && !eventSlotDetail && groupedEventSlots">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Von
                                </th>
                                <th class="text-left">
                                    Beschreibung
                                </th>
                                <th class="text-left">
                                    Aufgaben
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="eventSlot in filteredEventSlots(groupedEventSlots)" :key="eventSlot.id">
                                <td v-on:click="selectSlot(eventSlot)">
                                    {{ eventSlot.fromDate }} - {{ eventSlot.untilDate }}
                                </td>
                                <td v-on:click="selectSlot(eventSlot)" class="text-left">
                                    {{ eventSlot.text }} <br/>
                                    <small>{{ eventSlot.room }}</small>
                                </td>
                                <td class="text-left" @click="openTaskEditor(eventSlot)">
                                    <div 
                                        v-html="eventSlot.task"
                                    >
                                    </div>
                                    <v-icon small @click="taskEditor = eventSlot" v-if="!eventSlot.task">mdi-pencil</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-overlay fluid fill-height v-if="taskEditor">
                    <v-card style="margin: 3em 3em; width: 500px; height: 500px" absolute fluid>
                        <v-list-item three-line fluid fill-height>
                            <v-list-item-content fill-height>
                                <v-textarea
                                        ref="taskTextarea"
                                        clearable
                                        rows="1"
                                        auto-grow
                                        full-width
                                        fill-height
                                        v-model="taskEditor.task"
                                        @change="slotTaskChanged(taskEditor)"
                                        >
                                        {{ taskEditor.task }}
                                    </v-textarea>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions align-end justify-end>
                            <v-btn
                                variant="text"
                                color="deep-purple-accent-4"
                                @click="taskEditor = false"
                            >
                                Schließen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-overlay>
            </div>
        </div>
    </div>
</template>
